import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import { Disclaimer } from "../../components/Old2005BlogPage";

const OldBlogIndexPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog" />
	<h2>Old 2005 Blog</h2>
	<div className="main--centerwrapper">
		<StaticImage
		src="../../images/photos/pressRes/jesseinrocky.jpg"
		alt="Jesse Rivest press image"
		placeholder="blurred"
		layout="constrained"
		className="main--centered"
		imgClassName="img--bordered-small"
		height={333}
		/>
	</div>
	<Disclaimer />
	<h3>Choose a month</h3>
	<ul>
		<li><Link to="201004/">2010 April</Link></li>
		<li><Link to="200912/">2009 December</Link></li>
		<li><Link to="200909/">2009 September</Link></li>
		<li><Link to="200906/">2009 June</Link></li>
		<li><Link to="200905/">2009 May</Link></li>
		<li><Link to="200904/">2009 April</Link></li>
		<li><Link to="200902/">2009 February</Link></li>
		<li><Link to="200812/">2008 December</Link></li>
		<li><Link to="200811/">2008 November</Link></li>
		<li><Link to="200808/">2008 August</Link></li>
		<li><Link to="200807/">2008 July</Link></li>
		<li><Link to="200806/">2008 June</Link></li>
		<li><Link to="200803/">2008 March</Link></li>
		<li><Link to="200801/">2008 January</Link></li>
		<li><Link to="200712/">2007 December</Link></li>
		<li><Link to="200711/">2007 November</Link></li>
		<li><Link to="200708/">2007 August</Link></li>
		<li><Link to="200706/">2007 June</Link></li>
		<li><Link to="200705/">2007 May</Link></li>
		<li><Link to="200704/">2007 April</Link></li>
		<li><Link to="200703/">2007 March</Link></li>
		<li><Link to="200702/">2007 February (returned to New Zealand to stay)</Link></li>
		<li><Link to="200701/">2007 January (popped over to Australia)</Link></li>
		<li><Link to="200612/">2006 December</Link></li>
		<li><Link to="200611/">2006 November</Link></li>
		<li><Link to="200610/">2006 October (arrived in Wellington, New Zealand)</Link></li>
		<li><Link to="200609/">2006 September</Link></li>
		<li><Link to="200608/">2006 August</Link></li>
		<li><Link to="200607/">2006 July</Link></li>
		<li><Link to="200606/">2006 June</Link></li>
		<li><Link to="200605/">2006 May</Link></li>
		<li><Link to="200604/">2006 April</Link></li>
		<li><Link to="200603/">2006 March</Link></li>
		<li><Link to="200602/">2006 February (returned to Canada)</Link></li>
		<li><Link to="200601/">2006 January</Link></li>
		<li><Link to="200512/">2005 December (hopped over to Southeast Asia)</Link></li>
		<li><Link to="200511/">2005 November</Link></li>
		<li><Link to="200510/">2005 October</Link></li>
		<li><Link to="200509/">2005 September</Link></li>
		<li><Link to="200508/">2005 August</Link></li>
		<li><Link to="200507/">2005 July</Link></li>
		<li><Link to="200506/">2005 June</Link></li>
		<li><Link to="200505/">2005 May (arrived in Australia)</Link></li>
		<li><Link to="200504/">2005 April</Link></li>
		<li><Link to="200503/">2005 March</Link></li>
		<li><Link to="200502/">2005 February</Link></li>
		<li><Link to="200501/">2005 January</Link></li>
	</ul>
  </Layout>
);

export default OldBlogIndexPage;
